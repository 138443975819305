<template>
  <div class="comp-hot-box">
      <div class="input-box">
          <div class="search-box" >
              <input type="text" v-model="SoName"  class="input-search" placeholder="请输入想要搜索的商品" >
              <div class="search-icon" @click="GetSoList()"> 
                  <img src="@/assets/newImages/search-icon.png" alt="">
              </div>
          </div>
         <div class="price-box">
            <span class="price">价格</span>
            <div class="triangle-icon">
              <img src="@/assets/newImages/price-change.png" alt="Up Triangle" @click="GetSort" />
            </div>
          </div>
      </div>
      <div class="choose-all-box">
          <div class="choose-all">
              <div class="rem-checkbox" @click="toggleAllCheck">
              <div v-show="isAllChecked" class="float-gou-icon">
                <div class="bg-img"></div>
              </div>
            </div>
            全选
          </div>
          <div class="goods-totle">
              共{{total}}件
          </div>
      </div>
    <div class="JewelryMall-list">
      <div  v-for="(item, index) in WarehouseLists" :key="index" 
       :class="{
                'JewelryMall-list-item': !isAllChecked,
                'JewelryMall-list-item-actived': chooseIdList.includes(item.id) || isAllChecked ,
              }"  @click="itemOnClick(item, index)">
        <div>
          <Hprice :price="item.bean" />
        </div>
        <div class="pic">
          <img :src="item.cover" alt="" />
        </div>
        <div class="item-data">
          <p class="name elips1">{{ item.name }}</p>
          <div class="title2">
            {{item.dura_alias}}
          </div>
        </div>
      </div>
    </div>
    <div class="luckypagination">
      <Pagination v-show="PageNum>0" :total="PageNum" :page.sync="page_num" :limit.sync="per_page" @pagination="handleCurrentChange" />
    </div>
     <div class="table-footer">
        <div class='footer-text'>已选{{chooseNum}}件</div>
        <Hprice :price="allPrice" />
        <div class="btn-submit" v-if="tabindex === 0" @click="WholeExtract">提货</div>
        <div class="btn-submit" v-else @click="WholeCash">回收</div>
    </div>
     
  </div>
</template>

<script>
import { Storage, Extract, Cash, Give, PersonalInfo } from "@/network/api.js";
import { mapMutations } from "vuex";
import { titleList, priceSearch } from "../../Lucky/index.js";
import leftFloat from "@/components/left-float/index.vue";
import Hprice from '../../../components/common/Hprice.vue';
export default {
  name: "JewelryMall",
  components: {
    leftFloat,
    Hprice,
  },
   props: {
    tabindex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sort: 0, //0= 时间排序  1 按照价格排序
      sortTxt: "价格从高到低", //排序显示
      min_num: "0", //价格区间(开始)
      max_num: "50", //价格区间(结束)
      outidx: 0,
      smllidx: 0,
      loadingShow: true,
      outlist: [],
      SoName: "",
      WarehouseLists:[],
      //分页
      per_page: 0,
      PageNum: 0,
      page_num: 1, //页码

      titleList,
      tabId: 0,
      priceSearch,
      current: 0,
      priceSearchIndex: undefined,
      total:0,
      isAllChecked: false,
      isActive:false,
      acShop: [], //选中的数组
      aclistindex: null, //选中下标
      PickCode: 9, //提货状态
      chooseIdList: [],
      bean: 0,
      chooseNum: 0, //选中数量
      AllWarehouseObj: {},
    };
  },
  created() {
    this.GetStorage(0);
  },
   computed: {
    SelectList() {
      const array = this.priceselectList.map((item) => {
        return {
          label: item?.label || item,
          pvalue: item?.pvalue || item,
        };
      });
      return array;
    },
    parseSelectList() {
      const array = this.selectList.map((item) => {
        return {
          label: item?.label || item,
          value: item?.value || item,
        };
      });
      return array;
    },
    allPrice() {
      let price = 0;
      this.chooseIdList.forEach((item) => {
        price = price * 1 + this.AllWarehouseObj[item + ""].bean * 1;
      });
      return price;
    },
    WarehouseIdLists() {
      const arr = this.WarehouseLists.map((item) => {
        return item.id;
      });
      return arr;
    },
    IS_Select_All() {
      let flag = true;
      this.WarehouseIdLists.forEach((item) => {
        if (!this.chooseIdList.includes(item)) {
          flag = false;
        }
      });
      return flag;
    },
  },
    watch: {
        WarehouseLists(val) {
          setTimeout(() => {
            this.loadingShow = false;
          }, 100);
          val.forEach((item) => {
            this.AllWarehouseObj[item.id + ""] = item;
            if (!this.chooseIdList.includes(item.id)) {
            }
          });
          console.log(this.AllWarehouseObj,'this.AllWarehouseObj')

        },
         tabindex(newVal, oldVal) {
          if (newVal === 0) {
            //提货
            this.GetTakingList(1, this.PickCode);
          } else if (newVal === 1) {
            //回收
            this.getdata(1);
          }
        },
      },

  methods: {
     // 获取取回记录数据
    getdata(num) {
      Storage(num, this.sort, 2).then((res) => {
        this.tableData.total = res.data.data.total;
        this.tableData.pageSize = res.data.data.per_page;
        this.tableData.list = res.data.data.data;
      });
    },
     // 提货列表
    GetTakingList(num, code) {
      Storage(num, 0, code).then((res) => {
        this.acceptData.total = res.data.data.total;
        this.acceptData.pager = res.data.data.per_page;
        this.acceptData.list = res.data.data.data;
        this.acceptData.current_page = res.data.data.current_page;
      });
    },
    //获取仓库列表(第一页)
    GetStorage(num) {
      this.loadingShow = true;
      Storage(num, this.sort).then((res) => {
        if (res.data.data.data.length > 0) {
          this.WarehouseLists = res.data.data.data;
          this.total = Number(res.data.data.total);
          this.per_page = Number(res.data.data.per_page);
          this.page_Num = Number(res.data.data.current_page);
          console.log(this.WarehouseLists,'this.WarehouseLists')
        } else {
          if (num == "1") {
            this.WarehouseLists = res.data.data.data;
            this.total = Number(res.data.data.total);
            this.per_page = Number(res.data.data.per_page);
            this.page_Num = Number(res.data.data.current_page);
            this.loadingShow = false;
            console.log(this.WarehouseLists,'this.WarehouseLists')
          } else {
            this.GetStorage("1");
          }
        }
        
      });
    },
    //选中
    itemOnClick(item, index) {
      if (this.chooseIdList.includes(item.id)) {
        this.chooseIdList.splice(this.chooseIdList.indexOf(item.id), 1);
        this.bean -= item.bean * 1;
        this.chooseNum -= 1;
      } else {
        this.bean += item.bean * 1;
        this.chooseNum += 1;
        this.chooseIdList.push(item.id);
      }
      console.log(this.chooseNum,this.bean,'this.chooseNum',this.chooseIdList)
    },
    //多选提取
    WholeExtract() {
      let data = this.chooseIdList;
      Extract(data).then((res) => {
        if (res.data.code == 200) {
          this.num = 0;
          this.bean = 0;
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.GetPersonalInfo();
          this.GetStorage(this.page_Num);
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
    //多选回收
    WholeCash() {
      let data = this.chooseIdList;
      Cash(data).then((res) => {
        this.num = 0;
        this.bean = 0;
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.GetPersonalInfo();
          this.GetStorage(this.page_Num);
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
      this.chooseIdList = [];
    },

    // 搜索装备
    dusearchtype() {
      GetShop(
        this.max_num,
        this.min_num,
        this.sort,
        this.page_num,
        this.actype,
        this.searchtype
      ).then((res) => {
        this.ShopArr = res.data.data.data;
        this.total = res.data.data.total;
        this.page_size = res.data.data.per_page;
      });
    },
    //价格排序
    GetSort() {
     if(this.sort === 0){
        this.sort = 1;
     }else if(this.sort === 1){
          this.sort = 0;
     }
        Storage(this.page_Num, this.sort, 0).then((res) => {
          if (res.data.data.data.length > 0) {
            this.WarehouseLists = res.data.data.data;
            this.PageNum = Number(res.data.data.total);
            this.per_page = Number(res.data.data.per_page);
          }
        });
      console.log(this.sort,'this.sort')

    },
    //全选
     toggleAllCheck() {
      this.isAllChecked =!this.isAllChecked;
      this.isActive = !this.isActive;
      // 在这里可以添加逻辑来处理全选框的状态变化，例如选中或取消选中所有商品
      if (this.IS_Select_All == false) {
        const array = [
          ...new Set([...this.chooseIdList, ...this.WarehouseIdLists]),
        ];
        this.chooseIdList = array;
      } else {
        // this.chooseIdList = this.chooseIdList.splice()
        this.chooseIdList = this.chooseIdList.filter((item) => {
          return !this.WarehouseIdLists.includes(item);
        });
      }
    },
    priceInterval(price, i) {
      const p = price.split("-");
      this.min_num = p[0];
      this.max_num = p[1];
      this.priceSearchIndex = i;
    },
    currentChange(i) {
      this.current = i;
    },
    handleCurrentChange(val) {
      this.GetStorage(val.page);
      this.page_Num = val.page;
    },
    //搜索
    GetSoList() {
     
    },
    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },

    ...mapMutations(["User"]),
  },
};
</script>

<style lang="scss" scoped>
.comp-hot-box {
  position: relative;
}

.input-box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          margin-bottom: 0.16rem;
            .search-box {
                width: 2.2rem;
                background: #000000;
                opacity: 0.7;

                .input-search {
                  width: 1.6rem;
                   height: 0.29rem;
                  font-family: PingFang SC, PingFang SC;
                  font-size: 0.12rem;
                  color: #A4A4A4;
                  line-height:0.29rem;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                  text-decoration: none;
                }

                .search-icon {
                    width: 0.61rem;
                    height: 0.29rem;
                    background: url(../../../assets/newImages/search-btn-bg.png) no-repeat;
                    background-size: 100% 100%;
                    background-position: 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;                  


                    img {
                      width: 0.16rem;
                      height: 0.16rem;
                      margin-bottom: 0.02rem;
                    }
                }

            }

            .price-box {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: 1.15rem;
                height: 0.29rem;
                margin-left: 0.27rem;
                font-size: 0.12rem;
                color: #A4A4A4;
                line-height: 0.22rem;
                text-align: left;
                font-style: normal;
                background: url(../../../assets/newImages/price-change-bg.png) no-repeat;
                background-size: 100% 100%;
                background-position: 0 0;
                position: relative;

                .triangle-icon{
                  position: absolute;
                  right: 0.01rem;

                  img {
                    width: 0.19rem;
                    height: 0.19rem;
                  }
                }

            

          
        
      
    }
}

.choose-all-box {
  width: 100%;
  height: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.1rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 0.12rem;
  color: #FFFFFF;

    .rem-checkbox {
          position: relative;
          width: 0.18rem;
          height: 0.18rem;
          background: #413f3e;
          margin-right: 0.08rem;
          .float-gou-icon {
            position: absolute;
            width: 0.16rem;
            height: 0.16rem;
            left: 0;
            bottom: 0;
            @include bgImg("check-box-active");
          }
        }

   .choose-all {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
   


    .checkbox-input {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 10px;
      background-color: #413F3E;
    }
      /* 隐藏默认的复选框样式 */
    input[type="checkbox"]:checked{
      -webkit-appearance:none;
    }


    .checkbox-input:checked  {
      background-color: transparent;
       background: url(../../../assets/newImages/check-box-active.png) no-repeat;
       background-size: 100% 102%;
       background-position: 0 0;
      border-color: transparent;
    }


}
.JewelryMall-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .JewelryMall-list-item {
    width: 1.06rem;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0.03rem;
    padding: 0.06rem 0.13rem;
    margin-bottom: 0.07rem;

    .title2 {
      font-size: 0.1rem;
    }
    .pic {
      width: 100%;
      height: 0.6rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      position: relative;
      z-index: 1;

      img {
        display: block;
        height: 100%;
      }
    }

    .item-data {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      font-size: 0.1rem;

      .title2 {
        font-size: 0.1rem;
        
      }
    }

    .box_xian {
      width: 0.77rem;
      height: 0.04rem;
      background: transparent;
      margin: 0.2rem auto 0;
    }


    &:hover {
      .ceng {
        opacity: 1;
      }
    }
  }
  .JewelryMall-list-item:hover {
     background: url(../../../assets/newImages/box-active-bg.png) no-repeat;
     background-size: 100% 100%;
    background-position: 0 0;
     
  }
  .JewelryMall-list-item-actived {
    width: 1.06rem;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0.03rem;
    padding: 0.06rem 0.13rem;
    margin-bottom: 0.07rem;
     background: url(../../../assets/newImages/box-active-bg.png) no-repeat;
     background-size: 100% 100%;
    background-position: 0 0;

    .title2 {
      font-size: 0.1rem;
    }
    .pic {
      width: 100%;
      height: 0.6rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      position: relative;
      z-index: 1;

      img {
        display: block;
        height: 100%;
      }
    }

    .item-data {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      font-size: 0.1rem;

      .title2 {
        font-size: 0.1rem;
        
      }
    }

    .box_xian {
      width: 0.77rem;
      height: 0.04rem;
      background: transparent;
      margin: 0.2rem auto 0;
    }


    &:hover {
      .ceng {
        opacity: 1;
      }
    }
  }
}
.luckypagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.1rem 0;
}
.table-footer {
    position: fixed;
    bottom: 0.76rem;
    left: 0;
    width: 110%;
    height: 0.5rem;
    background: #4F4424;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 0.25rem 0.27rem;
  .footer-text {
    margin-right: 0.2rem;
    font-size: 0.12rem;

  }

  .btn-submit {
    position: absolute;
    right: 0.48rem;
    width: 1.01rem;
    height: 0.32rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 0.16rem;
    color: #151413;
    line-height: 0.32rem;
    text-align: center;
    font-style: normal;
    background: url(/img/btn-active-bg.00e90c04.png) no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
  }

}

.PageNum {
  width: 100%;

  li {
    background: #111111 !important;

    &.active {
      background: #4854c9 !important;
    }

    &:hover {
      color: #fff !important;
    }
  }

  .btn-prev,
  .btn-next {
    background: #111111;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .wrapper-box {
    z-index: 2001;
    width: 9rem;
    position: relative;
    background-image: url("../../../assets/images/other/jinkuang.png");
    background-size: 100% 100%;
    background-color: #131426;
    overflow: hidden;
    padding: 0 0.35rem;
    box-sizing: border-box;

    .wrapper-box-top {
      width: 100%;
      height: 0.03rem;
    }

    .tit {
      width: 100%;
      font-size: 0.24rem;
      text-align: center;
      line-height: 0.8rem;
    }

    .other {
      width: 100%;
      height: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 0.2rem;

      .ShuLiang {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 0.3rem;
          height: 0.3rem;
          background: rgba(255, 255, 255, 0.08);
          text-align: center;
          line-height: 0.3rem;
          font-size: 0.16rem;
          cursor: pointer;

          &:nth-child(2) {
            width: 0.5rem;
            margin: 0 0.1rem;
          }
        }
      }

      .ZongJiaGe {
        display: flex;
        align-items: center;

        p {
          font-size: 0.18rem;
        }

        span {
          margin-top: 0.03rem;
          width: 0.7rem;
          display: flex;

          img {
            vertical-align: middle;
            width: 0.2rem;
            height: 0.2rem;
            margin-bottom: 0.02rem;
          }

          font-size: 0.18rem;
          color: #75cd66;
        }
      }
    }

    .pic {
      width: 2.75rem;
      height: 2.75rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      /*background: url(../../assets/images/PublicImg/item-bg.jpg) no-repeat center;*/
      border: 1px solid;
      border-image: linear-gradient(rgba(0, 0, 0, 0), #ecb105) 20 20;

      background-size: cover;

      img {
        max-width: 80%;
        max-height: 80%;
      }
    }

    .btns {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0.2rem;

      span {
        display: block;
        width: 3rem;
        height: 0.64rem;
        background: hsla(0, 0%, 100%, 0.06);
        /*border: 1px solid hsla(0, 0%, 100%, .2);*/
        color: #e4e4e4;

        text-align: center;
        line-height: 0.64rem;
        font-size: 0.14rem;
        margin-right: 0.2rem;
        cursor: pointer;
      }

      .btn-two {
        margin-right: 0;
        /*background: #ffd322;*/
        background-image: url("../../../assets/images/OpenBox/btn.jpg");
        background-size: 100% 100%;
        color: #fff;
      }
    }

    .info {
      margin-top: 0.3rem;
      text-align: center;
      font-size: 0.12rem;
      color: rgba(255, 255, 255, 0.53);
    }

    .cancel {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      line-height: 0.5rem;

      img {
        width: 0.15rem;
        cursor: pointer;
      }
    }
  }
}

</style>
<style>
.custom-checkbox:checked {
  background: url(../../../assets/newImages/check-all.png) no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
  border-color: #FFC700; /* 改变边框颜色 */
}
</style>
